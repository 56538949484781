import { plugin, theme } from '@shc/ui'
import { Config } from 'tailwindcss'

const svgToDataUri = require('mini-svg-data-uri')

const selectCarat = (fill: string) =>
  svgToDataUri(`<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
  <path fill="${fill}" d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z" />
</svg>`)

const config: Config = {
  presets: [theme],
  content: ['src/**/*.{ts,tsx}', './node_modules/@shc/ui/**/**/*.{ts,tsx,jsx,js}'],
  theme: {
    extend: {
      colors: {
        lightGradientBlue: '#69c8d3',
        darkGradientBlue: '#18808c',
        campaign: {
          primary: {
            600: '#c9510d',
            700: '#a54209',
            DEFAULT: '#c9510d',
          },
          secondary: {
            50: '#f3faff',
            600: '#00548e',
            800: '#0d2f4f',
            DEFAULT: '#0d2f4f',
          },
          tertiary: {
            700: '#17758f',
            800: '#125b70',
            DEFAULT: '#17758f',
          },
          gold: {
            50: '#fefbf0',
            100: '#fdf5d9',
            400: '#cca360',
          },
          gray: {
            100: '#eceff1',
          },
        },
      },
      spacing: {
        // Add custom spacing hacks for that pixel perfect render!
        4.25: '1.125rem', // 18px
        4.5: '1.1875rem', // 19px; eg: large button with outline
      },
      lineHeight: {
        // Ensuring we have line height consistency for the utility classes
        3: '1.0625rem',
        6: '1.5rem',
        7: '1.75rem',
        8: '1.875rem',
        9: '2rem',
        10: '2.25rem',
        11: '2.625rem',
        12: '4rem',
      },
      backgroundSize: {
        auto: 'auto',
        cover: 'cover',
        contain: 'contain',
        '50%': '50%',
        '75%': '75%',
      },
      borderRadius: {
        DEFAULT: '0.3125rem', // 5px if base font-size is 16px
        none: '0px',
        xl: '0.625rem',
        '2xl': '0.75rem',
      },
      borderWidth: {
        DEFAULT: '1px',
        0: '0',
        2: '2px',
        3: '3px',
        4: '4px',
      },
      boxShadow: {
        DEFAULT: '2px 2px 6px rgba(0, 0, 0, 0.1)',
      },
      backgroundImage: {
        'select-carat': `url("${selectCarat('#42484d')}")`,
        'select-carat-disabled': `url("${selectCarat('#00000066')}")`,
        cacti: "url('/assets/backgrounds/cacti.webp')",
        coastline: "url('/assets/backgrounds/coastline.webp')",
        floral: "url('/assets/backgrounds/floral.webp')",
        'gold-starburst': "url('/assets/backgrounds/gold-burst.webp')",
        'gold-starburst-right': "url('/assets/backgrounds/gold-burst-right.webp')",
        petals: "url('/assets/backgrounds/petals.webp')",
        succulent: "url('/assets/backgrounds/succulent.webp')",
        yucca: "url('/assets/backgrounds/yucca.webp')",
        'white-starburst': "url('/assets/backgrounds/white-burst.webp')",
        'white-starburst-left': "url('/assets/backgrounds/white-burst-left.webp')",
      },
      dropShadow: {
        'sm-bottom': '0 2px 2px rgba(0, 0, 0, 0.05)',
        'darken-hover': '0 4px 3px rgba(0, 0, 0, 0.15)', // Increase opacity for a darker shadow
      },
      debugScreens: {
        position: ['bottom', 'right'],
      },
      width: {
        'grid-5': 'calc( ( 100% - ( 1.25rem * 4 ) ) / 5 )',
        'grid-3/12': 'calc( ( 100% - ( 1.25rem * 1 ) ) / (12/3) )',
        'grid-5/12': 'calc( ( 100% - ( 1.25rem * 1 ) ) / (12/5) )',
        'grid-7/12': 'calc( ( 100% - ( 1.25rem * 1 ) ) / (12/7) )',
        'grid-9/12': 'calc( ( 100% - ( 1.25rem * 1 ) ) / (12/9) )',
      },
      maxHeight: {
        '10/12': '83.333333%',
      },
      zIndex: {
        1: '1',
      },
      keyframes: {
        scrollLeft: {
          '0%': { transform: 'translateX(0)' },
          '20%': { transform: 'translateX(0)' },
          '100%': { transform: 'translateX(-50%)' },
        },
      },
      animation: {
        scrollLeft: 'scrollLeft 15s ease-in-out infinite',
      },
    },
  },
  plugins: [require('@tailwindcss/forms'), require('tailwindcss-debug-screens'), plugin],
}

export default config
